import Home from "./Displays/Home";
import CombinedWindSolar from "./Charts/CombinedWindSolar";
import Interchange from "./Charts/Interchange";
import OperationsDisplays from "./Displays/OperationsDisplays";
import FuelMix from "./Charts/FuelMix";
import SupplyAndDemandChart from "./Charts/SupplyAndDemand";
import RegionalDirectionalTransfer from "./Charts/RegionalDirectionalTransfer";
import GenerationOutages from "./Charts/GenerationOutages";
import Pod from "./Components/Pod";
import RealTimeTotalLoad from "./Charts/RealTimeTotalLoad";
import AreaControlError from "./Charts/AreaControlError";
import Disclaimer from "./Components/Disclaimer";
import NextDayShortTermReserveRequirement from "./Charts/NextDayShortTermReserveRequirement";


export interface RouteOptionsConfig {
    option: string;
    description: string;
}

export interface RoutesConfig {
    name: string;
    route: string;
    component: JSX.Element;
    addLineBreak?: boolean;
    options?: RouteOptionsConfig[]
}

const routes: RoutesConfig[] = [
    {
        name: "Home",
        route: '/',
        component: <Home />
    },
    {
        name: "Operations Displays",
        route: '/operations-displays',
        component: <OperationsDisplays />,
        addLineBreak: true,
    },
    {
        name: "Combined Wind and Solar",
        route: '/cwsc',
        options: [
            {
                option: "twoDays=true",
                description: "Showing Two Days"
            },
        ],
        component: <Pod title="Wind and Solar Forecast and Real-Time Generation"
        Chart={CombinedWindSolar} />
    },
    {
        name: "Interchange",
        route: '/interchange',
        component: <Pod title="Region-Wide Total Imports(-)/Exports(+)"
        Chart={Interchange} />
    },
    {
        name: "Fuel Mix",
        route: '/fuelmix',
        component: <Pod title="Fuel Mix" 
        Chart={FuelMix} />
    },
    {
        name: "Supply And Demand",
        route: '/supplydemand',
        options: [
            {
                option: "hideTitleBar=true",
                description: "Hidden Title Bar"
            }
        ],
        component: <Pod title="Supply and Demand" 
             Chart={SupplyAndDemandChart} />
    },
    {
        name: "Regional Directional Transfer",
        route: '/rdt',
        component: <Pod title="Regional Directional Transfer"
        Chart={RegionalDirectionalTransfer} />
    },
    {
        name: "Generation Outages",
        route: '/genout',
        component: <Pod title="Generation Outages"
        Chart={GenerationOutages} />
    },
    {
        name: "Real-Time Total Load",
        route: '/rttl',
        component: <Pod title="Real-Time Total Load" Chart={RealTimeTotalLoad} />
    },
    {
        name: "Next-Day Short-Term Reserve Requirement",
        route: '/resreq',
        component: <Pod title="Next-Day Short-Term Reserve Requirement" Chart={NextDayShortTermReserveRequirement} />
    },
    {
        name: "ACE",
        route: '/ace',
        component: <Pod title="ACE Chart" Chart={AreaControlError} />
    },
    {
        name: "Disclaimer",
        route: '/disclaimer',
        component: <Disclaimer extraMargin={true} />
    },
];

export default routes;
