import { useEffect, useState, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'

import { MisoColors } from '../colors';
import { DESCRIPTION_MESSAGE, DOWNLOAD_MESSAGE, FUEL_MIX_SUFFIX, POPOUT_MESSAGE } from '../Constants';
import { ChartProps } from '../Interfaces/Charts';
import VCenteredModal from '../Components/VCenteredModal';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { Button } from 'react-bootstrap';
import { Download } from '../Utils/Downloader';

const ColorLookup: {[CATEGORY: string]: string} = {
    "Coal": MisoColors.misoDrkGry,
    "Natural Gas": MisoColors.misoBlue,
    "Nuclear": MisoColors.misoOrange,
    "Wind": MisoColors.misoGreen,
    "Solar": MisoColors.misoLgtOrng,
    "Other": MisoColors.misoLgtGry,
    "Imports": MisoColors.darkTeal
};

let totalMW = '';
let intervalString: string;

let initialOptions: Highcharts.Options = {
    credits: {
        enabled: false
    },
    title: {
        text: `<span class="fs18 bold">${totalMW} MW</span><br><span class="fs14 fw-normal">Total Megawatts</span>`,
        align: 'center',
        verticalAlign: 'middle',
        y:-35,
    },
    tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>'
    },
    legend: {
        enabled: true,
        width: '100%',
        layout: 'horizontal',
        itemMarginBottom: 5,
        itemStyle: {
            display: "flex"
            },
        className:'w-100 d-flex justify-content-between',
        labelFormatter: function () {
            const t = this as any;
            return `<div class="fs14 w-100 d-flex justify-content-between margin-btm-5"><div class="bold">${t.name} </div> <div class="fw-normal"> ${t.y.toLocaleString("en-US")} MW</div></div>`;
        }
    },
    lang: {
        thousandsSep: ',',
        noData: "No data was received",
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    responsive: {
        rules: [{
            condition: {
                maxWidth: 411
            },

            chartOptions: {
                title: {
                    y: -55,
                },
            }
        }]
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            showInLegend: true,
        }
    },
    pane: {
        center: ['50%', '50%'],
        size: '80%'
    },
}

function format(mix: any) {
    var formmattedMix = mix.map((fuel: any, i: number) => {
        return { name: fuel.CATEGORY, y: parseInt(fuel.ACT), color: ColorLookup[fuel.CATEGORY], className: "insetShadowSVG" }
    });
    return formmattedMix;
}


export default function FuelMix(props: ChartProps) {
    
    const [options, setOptions] = useState(initialOptions);
    const [rawData, setRawData] = useState("");
    const chartRef = useRef<HighchartsReactRefObject>(null);
    const [buttonClicked] = [props.buttonClicked];
    const [showModal, setShowModal] = useState(false);
    const [modalBody, setModalBody] = useState<React.ReactNode>(<></>);
    const modalHeader: React.ReactNode = <span>Fuel Mix</span>;
    
    NoDataToDisplay(Highcharts);
    
    useEffect(() => {

        const descriptionBody: React.ReactNode = <><span>This chart represents the percentage of total megawatts supplied by the listed resources in the MISO footprint. The category listed as "Other" is the combination of Hydro, Pumped Storage Hydro, Diesel, Demand Response resources, External Asyncronous Resources and a varied asssortment of solid waste, garbage and wood pulp burners. </span><br /><br /> <span>This chart is updated every 5 minutes.</span></>;
        let downloadBody: React.ReactNode = <div className="download-modal">
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${FUEL_MIX_SUFFIX}`, "FuelMix", "json")}>Download JSON</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${FUEL_MIX_SUFFIX}`.replace("json", "csv"), "FuelMix", "csv")}>Download CSV</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${FUEL_MIX_SUFFIX}`.replace("json", "xml"), "FuelMix", "xml")}>Download XML</Button>
        </div>;

        switch (buttonClicked) {
            case DESCRIPTION_MESSAGE:
                setModalBody(descriptionBody);
                setShowModal(true);
                break;
            case DOWNLOAD_MESSAGE:
                setModalBody(downloadBody);
                setShowModal(true);
                break;
            case POPOUT_MESSAGE:
                const newWinddow = window.open('/charts/fuelmix', '_blank', 'width=800,height=600,noopener,noreferrer');
                if (newWinddow) newWinddow.opener = null;
                break;
            default:
                break;
        }
    }, [buttonClicked, rawData]);

    useEffect(() => {
        function fetchData(): void {
            if(chartRef.current === null){
                return;
            }
            const chart = chartRef.current.chart;
            chart.showLoading();

            fetch (process.env.REACT_APP_DATABROKER_URL + '' + FUEL_MIX_SUFFIX)
            .then(response => {return response.json()})
            .then(response => {
                setRawData(response);
                let data = format(response.Fuel.Type);
                totalMW = parseInt(response.TotalMW).toLocaleString("en-US");
                
                intervalString = response.RefId;
                
                let seriesPieOptionsDesktop: Highcharts.SeriesPieOptions = {
                    innerSize: "80%",
                        description: "fuelmix",
                        dataLabels: [{
                            enabled: false
                        }],
                    type: 'pie',
                    data: data
                };

                setOptions({
                    chart: {
                        plotBackgroundColor: undefined,
                        plotBorderWidth: undefined,
                        plotShadow: false,
                        type: 'pie',
                        backgroundColor: 'transparent',
                        height: (function (s) {
                            var gridRowHgt = 50;
                            var cats = s.data?.length;
                            cats = cats || 1;
                            var newHgtpx = (cats) * (gridRowHgt) + 120;
                            return newHgtpx;
                        })(seriesPieOptionsDesktop),    
                        style: {
                            fontFamily: 'Lato, Arial, Helvetica, sans-serif',
                            whiteSpace:"wrap"
                            },
                    },
                    title: {
                        text: `<span class="fs18 bold">${totalMW} MW</span><br><span class="fs14 fw-normal">Total Megawatts</span>`
                    },
                    series: [seriesPieOptionsDesktop]
                });

                chart.hideLoading();
            })
            .catch(() => {
                setOptions({
                    title: {
                        text: ``
                    }
                });
                chart.hideLoading();
            });

            // Fixes rendering bug on iOS devices
            chart.redraw();
            chart.reflow();
        }

        fetchData();
        const FIFTEEN_MIN_MS = 1000 * 60 * 15;
        const interval = setInterval(() => fetchData(), FIFTEEN_MIN_MS)

        // return fires on unmount, prevent memory leak
        return() => clearInterval(interval);
    }, []);

    return (
        <div className="chart">
            <VCenteredModal show={showModal} onHide={() => setShowModal(false)} headercontent={modalHeader} bodycontent={modalBody} />
            <div className="interval">{intervalString}</div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
                />
        </div>
    )
}
