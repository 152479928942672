import Highcharts from 'highcharts'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'
import { useEffect, useRef, useState } from 'react'

import { ChartProps, XYPoint } from '../Interfaces/Charts'
import { RegionalDirectionalTransferData, RegionalDirectionalTransferInterval } from '../Interfaces/DataBroker'
import { FIFTEEN_MIN_MS, X_TICK_INTERVAL, get4Dates, convertDateTimeToISO } from '../Utils/DateHelpers'
import { formatNumber, formatShortDateForAxis } from '../Utils/StringFormatHelpers'
import { formatTooltipDateWithMinutes } from '../Utils/ToolTipHelpers'
import { MisoColors } from '../colors'
import { DESCRIPTION_MESSAGE, DOWNLOAD_MESSAGE, POPOUT_MESSAGE, RDT_SUFFIX } from '../Constants'
import VCenteredModal from '../Components/VCenteredModal';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { Button } from 'react-bootstrap'
import { Download } from '../Utils/Downloader'

// setup series
let nsl: XYPoint[] = [];
let snl: XYPoint[] = [];
let rawmw: XYPoint[] = [];
let udsflow: XYPoint[] = [];

// setup dates and labels
let nxtDayFriendly: string;
let mktDayFriendly: string;

let intervalString: string;

let initialOptions: Highcharts.Options = {
    credits: {
        enabled: false
    },
    title:{
        text: ''
    },
    lang: {
        noData: "No data was received"
    },
    plotOptions: {
        line: {
            marker: {
                enabled: false,
            },
        },
    },
    xAxis: [
        // Main axis
        {
            title: {
                text: `Hours EST`,
                style: {
                    fontWeight: 'bold',
                },
            },
            type: 'datetime',
        },
    ],
    yAxis: {
        title: {
            text: "MWs",
            style: {
                fontWeight: "bold",
            },
        },
        tickInterval: 1000,
    },
    tooltip: {
        shared: true,
        formatter: function() {
            if(this.points == null) return false;
            const x = new Date(this.x as string);
            return this.points.reduce(formatTooltip, '<b>' + formatTooltipDateWithMinutes(x) + ' EST</b>'); // points.reduce(format_func(prev_val, val), initial_prev_val)
        }
    },
    series: []
};

function formatTooltip(s: string, point: Highcharts.TooltipFormatterContextObject): string{
    
    const y = point.y as number;

    // keep the existing tooltip, add break line
    var tooltip = s + '<br />';
    
    // now add to it
    tooltip += '<span style="color:'+ point.color +'">\u25cf</span> '+ point.series.name +': <b>' + formatNumber(y) +' MW</b>';
    return tooltip;
}

function parseData(data: RegionalDirectionalTransferData): void {
    // clear out the data
    nsl = [];
    snl = [];
    rawmw = [];
    udsflow = [];

    intervalString = data.RefId;

    // get the dates
    var dates = get4Dates(convertDateTimeToISO(data.Interval[0].instantEST));
    mktDayFriendly = formatShortDateForAxis(new Date(dates[1]));
    nxtDayFriendly = formatShortDateForAxis(new Date(dates[3]));

    // parse the data
    data.Interval.forEach((interval: RegionalDirectionalTransferInterval) => {
        const x = convertDateTimeToISO(interval.instantEST).getTime();
        nsl.push({x, y: parseFloat(interval.NORTH_SOUTH_LIMIT)});
        snl.push({x, y: parseFloat(interval.SOUTH_NORTH_LIMIT)});
        rawmw.push({x, y: parseFloat(interval.RAW_MW)});
        udsflow.push({x, y: parseFloat(interval.UDSFLOW_MW)});
    });
}

export default function RegionalDirectionalTransfer(props: ChartProps) {

    const [options, setOptions] = useState(initialOptions);
    const chartRef = useRef<HighchartsReactRefObject>(null);
    const [showModal, setShowModal] = useState(false);
    const [modalBody, setModalBody] = useState<React.ReactNode>(<></>);
    const modalHeader: React.ReactNode = <span>Regional Directional Transfer</span>;
    NoDataToDisplay(Highcharts);
    
    useEffect(() => {
        const descriptionBody: React.ReactNode = <><span>
            The Regional Directional Transfer (RDT) chart provides a real-time view of transfer between the MISO South Region and the rest of MISO within the agreed upon administrative values. Negative values denote North to South flows. Positive values denote South to North flows.
            </span><br/><br/><span>
            • UDS Megawatt Flow - refers to the controlled and coordinated flow of electricity as determined by the dispatch instructions. Ensures generation meets demand in the most efficient and reliable manner.
            </span><br/><span>
            • Raw Megawatt Flow - refers to actual flow of electricity displays instantaneous calculated flow. Provides a direct and instantaneous snapshot of power being transferred across the network.
            </span><br/><span>
            • North-South/South-North Limits are not dynamically updated and may not always reflect real-time operational adjustments. The current administrative limits of 3,000 MW (North to South) and 2,500 MW (South to North) are used, respectively.
            </span><br/><br/><span>
            The graph is updated every 5 minutes.</span>
        </>;
        let downloadBody: React.ReactNode = <div className="download-modal">
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${RDT_SUFFIX}`, "RegionalDirectionalTransfer", "json")}>Download JSON</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${RDT_SUFFIX}`.replace("json", "csv"), "RegionalDirectionalTransfer", "csv")}>Download CSV</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${RDT_SUFFIX}`.replace("json", "xml"), "RegionalDirectionalTransfer", "xml")}>Download XML</Button>
        </div>;
        switch (props.buttonClicked) {
            case DESCRIPTION_MESSAGE:
                setModalBody(descriptionBody);
                setShowModal(true);
                break;
            case DOWNLOAD_MESSAGE:
                setModalBody(downloadBody);
                setShowModal(true);
                break;
            case POPOUT_MESSAGE:
                const newWinddow = window.open('/charts/rdt', '_blank', 'width=800,height=600,noopener,noreferrer');
                if (newWinddow) newWinddow.opener = null;
                break;
            default:
                break;
        }
    }, [props.buttonClicked]);

    useEffect(() => {
        function fetchData(): void {
            if(chartRef.current === null){
                return;
            }
            const chart = chartRef.current.chart;
            chart.showLoading();

            fetch(process.env.REACT_APP_DATABROKER_URL + "" + RDT_SUFFIX)
            .then(response => {
                return response.json();
            })
            .then(data => {
                parseData(data);
                setOptions({
                    xAxis: [
                        // Main axis
                        {
                            title: {
                                text: `Hours EST`,
                                style: {
                                    fontWeight: 'bold',
                                    color: MisoColors.black,
                                },
                            },
                            labels: {
                                formatter: function(this: Highcharts.AxisLabelsFormatterContextObject) {
                                    var dt = new Date(this.value);
                                    return dt.getHours().toString();
                                }
                            },
                            type: 'datetime',
                            tickInterval: X_TICK_INTERVAL,
                        },
                        // axis to add date to left
                        {
                            title: {
                                text: `${mktDayFriendly}`,
                                align: "low",
                                style: {
                                    fontWeight: 'bold',
                                    color: MisoColors.black,
                                },
                                offset: -30,
                            },
                            lineWidth: 0,
                        },
                        // axis to add date to right
                        {
                            title: {
                                text: `${nxtDayFriendly}`,
                                align: "high",
                                style: {
                                    fontWeight: 'bold',
                                    color: MisoColors.black,
                                },
                                offset: -30,
                            },
                            lineWidth: 0,
                        }
                    ],
                    series: [
                        {
                            name: "South to North Limit",
                            type: "line",
                            data: snl,
                            color: MisoColors.red,
                        },
                        {
                            name: "Raw Megawatt Flow",
                            type: "line",
                            data: rawmw,
                            color: MisoColors.misoBlue,
                        },
                        {
                            name: "UDS Megawatt Flow",
                            type: "line",
                            data: udsflow,
                            color: MisoColors.misoGreen,
                        },
                        {
                            name: "North to South Limit",
                            type: "line",
                            data: nsl,
                            color: MisoColors.red,
                        },
                    ]
                })
                chart.hideLoading();

                // Fixes rendering bug on iOS devices
                chart.redraw();
                chart.reflow();
            }).catch(() => {
                chart.hideLoading();
            });
        }

        fetchData();
        const interval = setInterval(() => fetchData(), FIFTEEN_MIN_MS)

        // return fires on unmount, prevent memory leak
        return() => clearInterval(interval);
    }, []);

    return (
        <div className='chart'>
            <VCenteredModal show={showModal} onHide={() => setShowModal(false)} headercontent={modalHeader} bodycontent={modalBody} />
            <div className="interval">{intervalString}</div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
            />
        </div>
    )
}
