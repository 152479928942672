import { useEffect, useState, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'

import { MisoColors } from '../colors';
import { DESCRIPTION_MESSAGE, DOWNLOAD_MESSAGE, POPOUT_MESSAGE, REAL_TIME_TOTAL_SUFFIX } from '../Constants';
import { ChartProps, XYPoint } from '../Interfaces/Charts';
import VCenteredModal from '../Components/VCenteredModal';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { Button } from 'react-bootstrap';
import { Download } from '../Utils/Downloader';
import { formatNumber } from '../Utils/StringFormatHelpers';
import { refId2Date } from '../Utils/DateHelpers';
import { formatTooltipDateWithMinutes } from '../Utils/ToolTipHelpers';
import { ClearedMWForHourData, ActualLoadForFiveMinuteInterval, MediumTermLoadForecastForHour, RealTimeLoadData } from '../Interfaces/DataBroker';

let clearedDemand: XYPoint[] = [];
let actualLoad: XYPoint[] = [];
let mediumTermLoadForecast: XYPoint[] = [];

let mktDay: Date;
let nxtDayDt: Date;

let intervalString: string;

function resetSeries() {
    clearedDemand = [];
    actualLoad = [];
    mediumTermLoadForecast = [];
}

function parseData(data: RealTimeLoadData): void {
    mktDay = refId2Date(data.LoadInfo.RefId);
    mktDay.setHours(0);
    nxtDayDt = new Date(mktDay);
    nxtDayDt.setDate(mktDay.getDate() + 1);

    intervalString = data.LoadInfo.RefId;

    // friend-ify dates

    resetSeries();

    data.LoadInfo.ClearedMW.forEach((mwForHour: ClearedMWForHourData) => {
        clearedDemand.push({
            x: parseInt(mwForHour.ClearedMWHourly.Hour) - 1,
            y: parseInt(mwForHour.ClearedMWHourly.Value)
        });
    });

    data.LoadInfo.MediumTermLoadForecast.forEach((forecastForHour: MediumTermLoadForecastForHour) => {
        mediumTermLoadForecast.push({
            x: parseInt(forecastForHour.Forecast.HourEnding) - 1,
            y: parseInt(forecastForHour.Forecast.LoadForecast)
        });
    });

    data.LoadInfo.FiveMinTotalLoad.forEach((fiveMinutIntervalLoadReport: ActualLoadForFiveMinuteInterval) => {
        let timeIntervalParts = fiveMinutIntervalLoadReport.Load.Time.split(':');
        let hours = parseInt(timeIntervalParts[0]);
        let minutes = parseInt(timeIntervalParts[1]);

        actualLoad.push({
            x: hours + minutes / 60,
            y: parseInt(fiveMinutIntervalLoadReport.Load.Value)
        });
    });
}

let initialOptions: Highcharts.Options = {
    credits: {
        enabled: false
    },
    title:{
        text: ''
    },
    lang: {
        noData: "No data was received"
    },
    plotOptions: {
        line: {
            marker: {
                enabled: false,
            },
        },
    },
    xAxis: [
        // Main axis
        {
            title: {
                text: `Hours EST`,
                style: {
                    fontWeight: 'bold',
                    color: MisoColors.black,
                },
            },
            tickInterval: 2,
        },
    ],
    yAxis: {
        title: {
            text: "MWs",
            style: {
                fontWeight: "bold",
            },
        },
        tickInterval: 5000,
    },
    tooltip: {
        shared: true,
        formatter: function() {
            if(this.points == null) return false;
            const exactMomentInDay = new Date(mktDay);
            exactMomentInDay.setHours(Math.floor(this.x as number));
            exactMomentInDay.setMinutes(Math.round((this.x as number % 1 * 60) / 5) * 5);
            return this.points.reduce(formatTooltip, '<b>' + formatTooltipDateWithMinutes(exactMomentInDay) + '</b>');
        }
    },
    series: []
};

function formatTooltip(s: string, point: Highcharts.TooltipFormatterContextObject): string{
    
    const y = point.y as number;

    // keep the existing tooltip, add break line
    var tooltip = s + '<br />';

    var pointSitsOnHourEnd = point.x as number % 1 === 0;
    if (point.series.name === 'Actual Load (MW)' && !pointSitsOnHourEnd) {
        // If user is scanning mouse across series, Actual Load has more dense data per unit on x-axis. Therefore, to display all relevant data in the tooltip with proper labeling, we manually write out the tooltip span here for
        // all series. For cases where we do sit on an even hour mark, or where there is no Actual Load data, we display the tooltip rows as expected.
        var lastHourEnd = Math.floor(point.x as number);
        tooltip += '<span style="color:'+ point.color +'">\u25cf</span> Actual Load (MW): <b>' + formatNumber(y) +' MW</b><br /><br />';
        tooltip += '<span><b>Through Hour: ' + lastHourEnd + '</span><br /><br />'
        tooltip += '<span style="color:'+ MisoColors.misoGreen +'">\u25cf</span> Cleared Demand (MW): <b>' + clearedDemand.at(lastHourEnd)?.y +' MW</b><br />';
        tooltip += '<span style="color:'+ MisoColors.darkGreen +'">\u25cf</span> Medium Term Load Forecast (MW): <b>' + mediumTermLoadForecast.at(lastHourEnd)?.y +' MW</b><br />';
    } else {
        tooltip += '<span style="color:'+ point.color +'">\u25cf</span> '+ point.series.name +': <b>' + formatNumber(y) +' MW</b>';
    }
    
    return tooltip;
}

export default function RealTimeTotalLoad(props: ChartProps) {
    
    const [options, setOptions] = useState(initialOptions);
    const chartRef = useRef<HighchartsReactRefObject>(null);
    const [buttonClicked] = [props.buttonClicked];
    const [showModal, setShowModal] = useState(false);
    const [modalBody, setModalBody] = useState<React.ReactNode>(<></>);
    const modalHeader: React.ReactNode = <span>Real-Time Total Load</span>;
    
    
    NoDataToDisplay(Highcharts);
    
    useEffect(() => {

        const descriptionBody: React.ReactNode = <><span>The Real-Time Total Load chart displays the hour ending values for integrated Medium Term Load Forecast (MTLF) and Cleared Demand for the MISO market. The Actual Load updates every 5 minutes and represents the load served in the MISO market.</span></>;
        let downloadBody: React.ReactNode = <div className="download-modal">
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${REAL_TIME_TOTAL_SUFFIX}`, "RealTimeTotalLoad", "json")}>Download JSON</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${REAL_TIME_TOTAL_SUFFIX}`.replace("json", "csv"), "RealTimeTotalLoad", "csv")}>Download CSV</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${REAL_TIME_TOTAL_SUFFIX}`.replace("json", "xml"), "RealTimeTotalLoad", "xml")}>Download XML</Button>
        </div>;

        switch (buttonClicked) {
            case DESCRIPTION_MESSAGE:
                setModalBody(descriptionBody);
                setShowModal(true);
                break;
            case DOWNLOAD_MESSAGE:
                setModalBody(downloadBody);
                setShowModal(true);
                break;
            case POPOUT_MESSAGE:
                const newWinddow = window.open('/charts/rttl', '_blank', 'width=800,height=600,noopener,noreferrer');
                if (newWinddow) newWinddow.opener = null;
                break;
            default:
                break;
        }
    }, [buttonClicked]);
    useEffect(() => {
        function fetchData(): void {
            if(chartRef.current === null){
                return;
            }
            const chart = chartRef.current.chart;
            chart.showLoading();
            fetch (process.env.REACT_APP_DATABROKER_URL + '' + REAL_TIME_TOTAL_SUFFIX)
            .then(response => { 
                return response.json();
            })
            .then(data => {
                parseData(data);
                setOptions({
                    xAxis: [
                        // Main axis
                        {
                            title: {
                                text: `${mktDay.toLocaleDateString('en-us', { month:"long", day:"numeric" })} (Hours EST)`,
                                style: {
                                    fontWeight: 'bold',
                                    color: MisoColors.black,
                                },
                            },
                            labels: {
                                formatter: function(this: Highcharts.AxisLabelsFormatterContextObject) {
                                    return ((this.value as number) % 24).toString();
                                }
                            },
                            tickInterval: 2,
                        },
                    ],
                    series: [
                        {
                            name: "Cleared Demand (MW)",
                            type: "line",
                            data: clearedDemand,
                            color: MisoColors.misoGreen,

                        },
                        {
                            name: "Actual Load (MW)",
                            type: "line",
                            data: actualLoad,
                            color: MisoColors.yellow,
                        },
                        {
                            name: "Medium Term Load Forecast (MW)",
                            type: "line",
                            data: mediumTermLoadForecast,
                            color: MisoColors.darkGreen,
                        },
                    ]
                })
            }).catch(() => {});
            chart.hideLoading();

            // Fixes rendering bug on iOS devices
            chart.redraw();
            chart.reflow();
        }

        fetchData();
        const FIVE_MIN_MS = 1000 * 60 * 5;
        const interval = setInterval(() => fetchData(), FIVE_MIN_MS)

        // return fires on unmount, prevent memory leak
        return() => clearInterval(interval);
    }, []);

    return (
        <div className='chart'>
            <VCenteredModal show={showModal} onHide={() => setShowModal(false)} headercontent={modalHeader} bodycontent={modalBody} />
            <div className="interval">{intervalString}</div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
                />
        </div>
    )
}
